export const Translations = [{
en: {
        "title": "Multi-language app",
        "label": "Select another language!",
        "about": "About",
        "home": "Home",
        "recrutement": "Participant enrollment",
        "loi": "Anti-gift and transparency laws",
        "suivi": "Medical device post-production",
        "saas": "Saas",
        "blog": "Blog",
        "inscription": "Sign up",
        "contact": "Contact",
        "services": "Services",
        "btn1": "Request a quote",
        "btn2": "Enroll now",
        "btn3": "Learn more",
        "home1": "Enroll",
        "home2": "new participants",
        "home3": "in a few clicks.",
        "home4": "We bring you closer to health professionnals and patients to boost your projects.",
        "ref0": "No need to take our word for it. Trust our",
        "ref0-0": "TESTIMONIALS",
        "ref0-1": "clients",
        "ref0-2": "instead.",
        "ref1": "“Health Squad was fast to enroll patients for our qualitative study. We obtained quality feedback to integrate patient needs in our product’s development.”",
        "ref2": "“We call Health Squad regularly to evaluate our clients’ medical devices. We particularly value their tester quality, schedule management and unforeseen event handling. Simply put,a guaranteed high quality and swift recruitment!“",
        "ref3": "“We needed to find patients without any medical training and as representative as possible for a new implant card. I appreciated the ease and speed of recruitment while respecting our criteria, even intricate.“",
        "numbers1": "Patients and health professionnals",
        "numbers2": "Studies",
        "numbers3": "Enrollments",
        "numbers4": "Conventions",
        "user": "My name is: {{name}}",
        "saas0": "Coming Soon",
        "saas1": "Your research. Under your control.",
        "saas2": "Everything’s",
        "saas3": "here.",
        "saas4": "Organize your interviews, user tests, workshops and online/offline surveys in record time.",
        "saas5": "Control your",
        "saas6": "benefits.",
        "saas7": "Access your anti-gift and transparency laws documents directly from your dashboard.",
        "saas8": "Choose",
        "saas9": "who, what, when and how.",
        "saas10": "Ask for specific profiles and plan the terms of your study, with GDPR law protection.",
        "service0": "Our",
        "service0-1": "tailor-made",
        "service0-2": "services for healthcare business companies.",
        "service1-1": "Participant enrollment",
        "service1-2": "Participant enrollment",
        "service1-3": "Call Health Squad and start enrolling the right participants at the right time for your qualitative or quantitative studies to boost your projects !",
        "service1-4": "Health professionnals, patients and all-comers",
        "service1-5": "Every study type: user tests, interviews, surveys, workshops etc.",
        "service1-6": "Online or offline",
        "service1-7": "Payment management and associated laws (anti-gift, transparence laws, GDPR etc.)",
        "service2-1": "Anti-gift and transparency laws",
        "service2-2": "Anti-gift and transparency laws",
        "service2-3": "We can help you complete procedures associated to benefit management and transparency laws",
        "service2-4": "From initiation to validation of the conventions",
        "service2-5": "Secure storage and process tracking",
        "service2-6": "Guaranteed compliance for all events",
        "service3-1": "Medical device post-production",
        "service3-2": "Medical device post-production",
        "service3-3": "Final user opinion needs to be taken into account even after your product’s commercialization. Interviewing the current users of your device becomes essential to conformto Europeandirective MDR 2017/745.Itis now essential tocontact users to collect usage data.",
        "service3-4": "Identify usable data and design support platforms",
        "service3-5": "Final user enrollment",
        "service3-6": "Result analysis and report redaction",
        "recrutement0": "Our Recruitment",
        "recrutement1": "Study participant enrollment",
        "recrutement2": "Recruitment is our mission. HealthSquad enrolls the right participants for all your studies. Whether qualitative or quantitative, we will make sure to get the right people for your medical device. Our objective : boost your project",
        "recrutement3": "HealthSquad adapts to your needs. Do you need a lot of health professionals for your study ? No problem. Nurses, dentists or biomedical technicians, nothing is impossible. We can also recruit patients or all-comers.",
        "recrutement4": "And HealthSquad recruits for all study types. User tests, interviews, surveys and online or offline workshops, you can stay assured that your study will be brought to completion.",
        "recrutement5": "Recruitment also means regulations. HealthSquad also takes care of participant payment and the associated laws (anti-gift and transparency laws,GDPR...).",
        "loi0": "Anti-gift laws",
        "loi1": "Anti-gift and transparency laws",
        "loi2": "HealthSquad helps you complete procedures regarding anti-gift and transparency laws.",
        "loi3": "The anti-gift law, also known as the DMOS law, and transparency laws, have no secrets for us",
        "loi4": "We create conventions for health professionals that we submit to authorities. Conventions are then validated and freely accessible.",
        "loi5": "We also guarantee full data protection of all conventions through GDPR law. HealthSquad takes care of the entire process and consequent data storage",
        "loi6": "With HealthSquad, conformity is guaranteed!",
        "Signup0": "Sign up",
        "Signup1": "To participate to our studies, please fill the form below",
        "Signup2": "Required field",
        "Signup3": "Join the",
        "Signup4": "Health Squad",
        "Signup5": "to make tomorrow’s medical products better",
        "Signup6": "Last name",
        "Signup7": "First name",
        "Signup8": "E-Mail address",
        "Signup9": "Gender",
        "Signup10": "Nationality",
        "Signup11": "Date of Birth",
        "Signup12": "Next",
        "Signup13": "A little more info",
        "Signup14": "These informations will allow us to pay you and improve our study planning.",
        "Signup15": "Phone Number",
        "Signup16": "Field of Work",
        "Signup17": 'Health Professional',
        "Signup18":'Agriculture', 
        "Signup19": `Craftsman`, 
        "Signup20": `Commercial`,
        "Signup21": `Company Director`, 
        "Signup22": `Executive`, 
        "Signup23": `Intermediate professions`, 
        "Signup24": `Employee`,
        "Signup25": `Worker`, 
        "Signup26": `Student`, 
        "Signup27": `Inactive/never worked`, 
        "Signup28": `Job title`, 
        "Signup29": `Speciality`, 
        "Signup30": `In :`, 
        "Signup31": `Private`, 
        "Signup32": `Public`, 
        "Signup33": `Address`, 
        "Signup34": `Street number`, 
        "Signup35": `Street name`, 
        "Signup36": `Additional information`, 
        "Signup37": `Zip code`, 
        "Signup38": `City`, 
        "Signup39": `Country`, 
        "Signup40": `I agree to receive HealthSquad’s newsletter and new information.`, 
        "Signup41": `By ticking this box, you agree that the information you have provided is subject to processing intended for the recruitment of study participants. Symplicity UserAdventure is responsible for the processing of your personal data. You can express your wish to revoke your consent or exercise your rights of access, rectification, erasure, opposition, limitation or portability directly to Symplicity UserAdventure at the following address :`,
        "Signup42": `The requested data is necessary to process your request. To find out more, you can read our personal data protection policy `, 
        "Signup43": `here`, 
        "Signup44": `.`,
        "Signup45": `Validate`,  
        "Suivi0": "Post-production tracking",
        "Suivi1": "Post-production tracking of medical devices",
        "Suivi2": "HealthSquad’s mission doesn’t stop after your product is commercialized !",
        "Suivi3": "Interviewing the current users of your medical device is now mandatory to conform to European directive MDR 2017/745. It is now necessary to collect usage data from finalusers.",
        "Suivi4": "We take care of every step of your post-production data collection needs !",
        "Suivi5": "HealthSquad identifies usable data and designs support platforms to collect it. We also enroll final users.",
        "Suivi6": "When the data has been collected, we analyze it and draft a complete report !",
        "aboutO": "About",
        "about1": "Who are we ?",
        "about2": "At HealthSquad, we unite the health sector actors. Patients, health professionals and manufacturers, everyone advances towards one goal : improve the world of healthcare.",
        "about3": "HealthSquad attaches great importance to regulation compliance. Anti-gift laws, trans-parency laws... We make the manufacturing and commercialization of medical deviceseasier for you.",
        "about4": "HealthSquad’s adventure started in 2021, when Maxime and Julien Aldegheri decided to launch their platform to help bring manufacturers and users closer, with a single goal in mind : boost your healthcare projects.",
        "about5": "From recruitment to planification of test sessions, HealthSquad takes care of everything, and brings you along the entire project !",
        "contactO": "A question ? Contact us.",
        "contact1": "We will contact you within 48 hours.",
        "contact2": "Name *",
        "contact3": "E-mail address *",
        "contact4": "Your request *",
        "contact5": "Send",
        "devis0": "Ask us for a quote.",
        "devis1": "We will contact you within 48 hours.",
        "devis2": "Last name",
        "devis3": "First name",
        "devis4": "E-mail address",
        "devis5": "Phone number",
        "devis6": "Company",
        "devis7": "Request type",
        "devis8": "Short description of your request",
        "devis9": "How would you like to be contacted ?",
        "blogHome0": "Go to articles",
        "blogHome1": "Our latest",
        "blogHome2": "news and articles",
        "blog0": "The company and the healthcare world’s most recent news.",
        "exemple0": "EXAMPLES",
        "exemple00": "When would you need to use",
        "exemple01": "HealthSquad’s services ?",
        "exemple02": "Using our services will allow you to get the right inputs from the right people at the right time to boost your projects",
        "exemple1": "Qualitative and quantitative studies",
        "exemple11": "We recruit future users or field experts to support your qualitative or quantitative studies. We cover all methods (user tests, interviews, surveys, focus groups...) online or offline.",
        "exemple2": "Development workshop",
        "exemple22": "Integrate your final users and field experts in your product or service’s development. Receive valuable input from users during Research & Development phases.",
        "exemple3": "Formation and final evaluation of a medical device",
        "exemple33": "Carrying out evaluations to gauge or validate a medical device’s ergonomics and its usage risks is mandatory to complete its technical sheet and obtain CE marking (EN 62366).",
        "exemple4": "Post-production tracking of medical devices",
        "exemple44" : "Interview the current users of your device is invaluable to conform to European directive MDR UE 2017/745. We help you identify and collect the right data..",
        "exemple5": "Expert opinions",
        "exemple55" : "We identify and enroll field experts for your product or service to help you obtain the right advice.",
        "exemple6": "Medical device usability",
        "exemple66" : "We help you integrate users into your medical device’s usability engineering process (Usability EN 62366).",
        "exemple7": "Market authorization maneuverability and legibility test for pharmaceuticals",
        "exemple77": "We recruit patients and health professionnals to help evaluate maneuverability and legibility of user information (instructions, quick start guide, packaging...) for your product’s market authorization.",
        "footer0": "Join Us",
        "footer1": "Terms and Conditions",
        "footer2": "Personal Data Protection Policy",
        "footer3": "Our office",
        "option2-1":"Mail",
        "option2-2":"Phone",
    },
fr: {
        "title": "Multi-language app",
        "label": "Select another language!",
        "about": "A propos",
        "home": "Accueil",
        "recrutement": "Recrutement de participants",
        "loi": "Loi anti-cadeau et dispositif transparence",
        "suivi": "Suivi post-production des dispositifs médicaux",
        "saas": "Saas",
        "blog": "Blog",
        "inscription": "Inscription",
        "contact": "Contact",
        "services": "Services",
        "btn1": "Demander un Devis",
        "btn2": "Recruter maintenant",
        "btn3": "En savoir plus",
        "home1": "Recrutez",
        "home2": "vos participants",
        "home3": "en quelques clics.",
        "home4": "Nous vous rapprochons des professionnels de santé et des patients pour booster vos projets en santé",
        "ref0": "Pas besoin de nous croire sur parole. Ecoutez plutôt nos",
        "ref0-0": "RÉFÉRENCES",
        "ref0-1": "Clients.",
        "ref0-2": "",
        "ref1": "“Health Squad a su être réactif dans le recrutement de patients pour notre étude qualitative. Nous avons obtenu des feedbacks de qualité pour intégrer les attentes des clients dans la conception de notre produit”",
        "ref2": "“Nous faisons appel régulièrement à la Health squad pour l'évaluation des dispositifs médicaux de nos clients. Nous apprécions particulièrement la qualité des profils recrutés, la gestion du planning et également des imprévus. Tout simplement, la garantie d'avoir un recrutement efficace et rapide!”",
        "ref3": "“Nous devions trouver des patients sans aucune formation médicale et le plus représentatifs possible pour évaluer une nouvelle carte d'implant. J'ai apprécié la simplicité et la rapidité de recrutement des profils en respectant l'ensemble de critères de recrutement même complexes”",
        "numbers1": "Patients et pro de santé",
        "numbers2": "Etudes",
        "numbers3": "Recrutements",
        "numbers4": "Conventions",
        "user": "My name is: {{name}}",
        "saas0": "À venir",
        "saas1": "Vos recrutements, sous votre contrôle.",
        "saas2": "Tout au même",
        "saas3": "endroit.",
        "saas4": "Organisez vos entretiens, tests utilisateur, ateliers, questionnaires en ligne ou en présentiel en un temps record.",
        "saas5": "Gérez vos",
        "saas6": "avantages.",
        "saas7": "Suivez vos documents concernant la loi anti-cadeau et le dispositif transparence directement depuis votre espace.",
        "saas8": "Choisissez",
        "saas9": "qui, quand, comment.",
        "saas10": "Demandez les profils de votre choix et planifiez les modalités de l'étude en toute sécurité selon la loi RGPD.",
        "service0": "Nos services",
        "service0-1": "sur-mesure",
        "service0-2": "pour les entreprises de la santé.",
        "service1-1": "Recrutement de participants",
        "service1-2": "Recrutement de participants",
        "service1-3": "Faites appel à la Health Squad et recrutez les bons participants au bon moment pour participer à vos études qualitatives ou quantitatives afin de booster votre projet en santé !",
        "service1-4": "Professionnels de santé, patients et tout venants",
        "service1-5": "Tous types d'études : Tests utilisateurs, entretiens, questionnaires, ateliers etc.",
        "service1-6": "En ligne et en présentiel",
        "service1-7": "Gestion de la rémunération et des réglementations associées (loi anti-cadeau, dispositif transparence, RGPD etc.)",
        "service2-1": "Loi anti-cadeau et dispositif transparence",
        "service2-2": "Loi anti-cadeau et dispositif transparence",
        "service2-3": "Nous vous accompagnons dans la gestion des processus concernant la loi d'encadrement des avantages et du dispositif transparence.",
        "service2-4": "De la constitution à la validation des conventions",
        "service2-5": "Suivi des processus et stockage sécurisé",
        "service2-6": "Conformité assurée de tous vos évènements",
        "service3-1": "Suivi post-production des dispositifs médicaux",
        "service3-2": "Suivi post-production des dispositifs médicaux",
        "service3-3": "L'effort de prise en compte des utilisateurs finaux ne s'arrête pas après la commercialisation de votre dispositif médical. Interroger les utilisateurs actuels de votre DM devient indispensable pour répondre aux nouvelles directives du MDR 2017/745. En effet, il est nécessaire de descendre jusqu'aux utilisateurs finaux afin de collecter des données d'utilisation.",
        "service3-4": "Identification des données à collecter et conception des supports",
        "service3-5": "Recrutement des utilisateurs finaux",
        "service3-6": "Analyse des résultats et rédaction du rapport",
        "recrutement0": "Nos Recrutements",
        "recrutement1": "Recrutement de participants",
        "recrutement2": "Le recrutement : mission acceptée ! HealthSquad recrute pour vous les bons participants pour participer à vos études. Qu’elles soient qualitatives ou quantitatives, HealthSquad saura cibler les profils adéquats à votre dispositif. Notre seul objectif : booster votre projet en santé !",
        "recrutement3": "HealthSquad s’adapte à votre demande. Besoin de profession-nels de santé pour votre étude ? Pas de problème. Infirmiers, dentistes ou encore technicien biomédicaux, rien n’est impossible. Et il en va de même si vous avez besoin de patients ou de tout venants.",
        "recrutement4": "Et HealthSquad recrute pour tous les types d’études. Tests utilisateurs, entretiens, questionnaires, ateliers en ligne ou en présentiel, soyez sûrs que votre étude sera menée à bien.",
        "recrutement5": "Mais qui dit recrutement, dit réglementations. HealthSquad s’occupe également de la rémunération de tous les participants à vos études, ainsi que des réglementations associées (loi anti-cadeau, dispositif transparence, RGPD, etc.)",
        "loi0": "La Loi anti-cadeau",
        "loi1": "Loi anti-cadeau et dispositif transparence",
        "loi2": "HealthSquad vous accompagne dans la gestion des démarches concernant la loi d’encadrement des avantages et du dispositif transparence.",
        "loi3": "La loi anti-cadeau, ou loi DMOS, ainsi que la transparence n’ont aucun secret pour nous !",
        "loi4": "Nous constituons des conventions pour les professionnels de santé que nous soumettons aux autorités compétentes. Les conventions seront ensuite validées.",
        "loi5": "Nous assurons également la sécurité des données et des conventions des tous les participants. HealthSquad s’occupe du suivi des processus et assure le stockage de toutes les données.",
        "loi6": "Avec HealthSquad, la conformité de tous vos évènements est garantie !",
        "Signup0": "Inscription",
        "Signup1": "Afin de pouvoir participer aux études, merci de compléter le formulaire ci-dessous",
        "Signup2": "Champ obligatoire",
        "Signup3": "Rejoignez la",
        "Signup4": "Health Squad",
        "Signup5": "pour améliorer les produits de santé de demain",
        "Signup6": "Nom",
        "Signup7": "Prénom",
        "Signup8": "Email",
        "Signup9": "Genre",
        "Signup10": "Nationalité",
        "Signup11": "Date de naissance",
        "Signup12": "Suivant",
        "Signup13": "Encore quelques petites informations",
        "Signup14": "Ces informations nous permettrons d'une part, de favoriser l'organisation de certaines études mais également de vous indemniser.",
        "Signup15": "Téléphone portable",
        "Signup16": "Profession",
        "Signup17": 'Professionnel de santé',
        "Signup18":'Agriculteur', 
        "Signup19": `Artisan`, 
        "Signup20": `Commerçant`,
        "Signup21": `Chef d'entreprise`, 
        "Signup22": `Cadre et profession Intellectuelle supérieure`, 
        "Signup23": `Professions Intermédiaires`, 
        "Signup24": `Employé`,
        "Signup25": `Ouvrier`, 
        "Signup26": `Etudiant`, 
        "Signup27": `Inactif ou chômeur n'ayant jamais travaillé`, 
        "Signup28": `Métier`, 
        "Signup29": `Spécialité`, 
        "Signup30": `Vous exercez :`, 
        "Signup31": `Dans le privé`, 
        "Signup32": `Dans le public`, 
        "Signup33": `Adresse`, 
        "Signup34": `Numéro de voie`, 
        "Signup35": `Nom de rue`, 
        "Signup36": `Complément d’adresse`, 
        "Signup37": `Code postal`, 
        "Signup38": `Ville`, 
        "Signup39": `Pays`, 
        "Signup40": `J'accepte de recevoir la NewsLetter et les informations concernant Health Squad.`, 
        "Signup41": `En cochant cette case, vous acceptez que les informations que vous avez fournies font l’objet d’un traitement informatisé destiné au recrutement des participants aux études.
        Symplicity UserAdventure est responsable du traitement de vos données personnelles. Vous pouvez exprimer votre souhait de revenir sur votre consentement ou exercer vos droits d’accès, de rectification, d’effacement, d’opposition, de limitation ou de portabilité directement auprès de Symplicity UserAdventure à l’adresse suivante :`,
        "Signup42": `Les données sollicitées sont nécessaires au traitement de votre demande.
                                Pour en savoir plus, retrouvez`, 
        "Signup43": `ici`, 
        "Signup44": `notre politique de protection des données personnelles.`,
        "Signup45": `Valider`,  
        "Suivi0": "Suivi-post-production",
        "Suivi1": "Suivi post-production des dispositifs médicaux",
        "Suivi2": "La mission d’HealthSquad ne s’arrête pas après la commercialisation de votre dispositif médical !",
        "Suivi3": "Interroger les utilisateurs actuels de votre DM devient indispensable pour répondre aux nouvelles directives du MDR 2017/745. Il est nécessaire de descendre jusqu’aux utilisateurs finaux afin de collecter des données d’utilisation.",
        "Suivi4": "Nous nous occupons de chaque étape du suivi post-production de vos dispositifs médicaux !",
        "Suivi5": "HealthSquad identifie des données à collecter, et s’occupe de la conception des supports de suivi. Et nous recrutons cette fois les utilisateurs finaux.",
        "Suivi6": "Et enfin, nous analysons les résultats du suivi et nous rédigeons un rapport complet !",
        "aboutO": "À propos",
        "about1": "Qui sommes-nous ?",
        "about2": "Chez HealthSquad, on réunit les différents acteurs de la santé. Patients, professionnels de santé et fabricants, tous se rassemblent autour d’un même objectif : faire avancer le monde de la santé.",
        "about3": "HealthSquad accorde une grande importance au respect des réglementations en vigueur. Loi anti-cadeau, dispositif transparence... Nous facilitons la fabrication et la mise sur le marché des dispositifs médicaux en se conformant à ses règles.",
        "about4": "L’aventure HealthSquad a commencé en 2021. Maxime et Julien Aldegheri ont décidé de se lancer et de créer la plateforme qui rapproche les fabricants des utilisateurs. Tout cela avec un objectif en tête : booster vos projets en santé.",
        "about5": "Du recrutement à la planification des sessions de tests, HealthSquad s’occupe de tout, et vous accompagne tout au long de votre projet !",
        "contactO": "Une question ? Contactez nous",
        "contact1": "Nous vous recontacterons sous 48h.",
        "contact2": "Nom *",
        "contact3": "Email *",
        "contact4": "Votre message *",
        "contact5": "Envoyer",
        "devis0": "Demandez votre devis dès maintenant.",
        "devis1": "Nous vous recontacterons sous 48h.",
        "devis2": "Nom",
        "devis3": "Prénom",
        "devis4": "Email",
        "devis5": "Téléphone",
        "devis6": "Votre entreprise",
        "devis7": "Type de demande",
        "devis8": "Courte description de votre demande",
        "devis9": "Comment souhaitez-vous être recontacté(e)?",
        "blogHome0": "Voir tous les articles",
        "blogHome1": "Nos derniers",
        "blogHome2": "articles et actualités.",
        "blog0": "Retrouvez nos actualités mais aussi celles du monde de la santé",
        "exemple0": "EXEMPLES",
        "exemple00": "Quand faire appel au service de recrutement",
        "exemple01": "de la Health squad ?",
        "exemple02": "Faire appel à la Health Squad vous permettra d'obtenir les bons inputs des bons profils au bon moment pour booster votre projet.",
        "exemple1": "Etude qualitative et quantitative",
        "exemple11": "Nous recrutons les futurs utilisateurs ou experts du domaine afin de soutenir vos différentes études qualitatives et quantitatives. Nous couvrons toutes les méthodologies (tests utilisateurs, entretiens, questionnaires, focus group etc.) en ligne et en présentiel.",
        "exemple2": "Atelier de conception",
        "exemple22": "Intégrez directement vos utilisateurs finaux et les experts du domaine dans la conception de votre produit ou service. Profitez des retours de vos utilisateurs finaux directement lors des phases de recherche et développement.",
        "exemple3": "Evaluation formative et sommative d'un dispositif médical",
        "exemple33": "Réaliser des évaluations pour évaluer ou valider l'ergonomie d'un dispositif médical et la maitrise des risques d'usage est incontournable pour compléter son dossier technique et obtenir le marquage CE (EN 62366).",
        "exemple4": "Suivi post-production des dispositifs médicaux",
        "exemple44" : "Interroger les utilisateurs actuels de votre dispositif médical devient indispensable pour répondre aux nouvelles directives du MDR (UE 2017/745). Nous vous aidons à les identifier et à recueillir les bonnes données pour alimenter votre dossier.",
        "exemple5": "Avis d'expert",
        "exemple55" : "Nous identifions et recrutons les experts du domaine de votre produit ou service pour vous aider à obtenir un avis éclairé.",
        "exemple6": "Aptitude à l'utilisation d'un dispositif médical",
        "exemple66" : "Nous vous aidons à intégrer vos utilisateurs finaux dans le processus d'ingénierie d'aptitude à l'utilisation de votre dispositif médical (Usability EN 62366).",
        "exemple7": "Test de maniabilité et lisibilité pour l'autorisation de mise sur le marché d'un médicament",
        "exemple77": "Nous recrutons les patients et professionnels de santé afin d'évaluer la maniabilité et la lisibilité des différentes informations utilisateur (notice, quick start guide, packaging etc.) pour l'AMM de votre médicament.",
        "footer0": "Rejoignez-nous",
        "footer1": "Mention légales / CGU",
        "footer2": "Politique de protection des données personnelles",
        "footer3": "Restons en contact",
        "option2-1":"Par Mail",
        "option2-2":"Par Téléphone",
    }
}]