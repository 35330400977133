import React, {useState, useMemo, useEffect} from 'react';
import {  Row, Col, Modal  } from 'react-bootstrap';
import { TouchableOpacity, Text} from 'react-native';


const ModalFin = ({show, Quit}) => {

    return (
      <Modal  show={show}  onHide={Quit} centered >
         
      <Modal.Header className="modal-header border-0" style={{justifyContent:'center',alignContent:'center',background: `linear-gradient(147deg, #319f8f, #3f52a9 52%, #a85b82)`}} closeButton closeVariant="white">
       <Modal.Title id="example-modal-sizes-title-sm">
       <Col style={{color:'white',fontWeight:'bold', fontSize:22, marginInline:20}}>Merci pour votre demande !</Col>
       </Modal.Title>
     </Modal.Header>
     <Modal.Body style={{background: `white`}}>
   
       {/* <Row>
     <Col style={{fontWeight:'bold', fontSize:22, marginInline:20}}>Votre demande est un cours de traitement.</Col>
     </Row> */}

     <Row>
     <Col style={{ fontSize:18, marginInline:20,marginTop: 10}}>Nous vous répondons directement par mail sous 48h.</Col>
     </Row>
     
      </Modal.Body>
      <Modal.Footer className="modal-footer border-0" style={{justifyContent: 'center', alignItems: 'center'}}>
      <TouchableOpacity onPress={Quit} style={{background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`,borderRadius:5,justifyContent: 'center', alignItems: 'center',elevation: 15,shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }, height:40, width:160}}>
      <Text style={{color:'white',fontWeight:'bold', fontSize:18, marginHorizontal:5}}>OK</Text>
      </TouchableOpacity>
     </Modal.Footer>
     
    
   </Modal>
    )
}

export default ModalFin

